* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
body {
  backface-visibility: hidden;
}

.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px;
}
.gg-close::after,
.gg-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 4px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
  color: #1a1a1a;
}
.gg-close::after {
  transform: rotate(-45deg)
}


@media (min-width: 768px) {

  .gg-close {
    width: 45px;
    height: 45px;
  }

  .gg-close::after,
  .gg-close::before {
    width: 40px;
    height: 8px;
  }

}